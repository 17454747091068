<template>
  <main>
    <b-tabs>
      <b-tab active>
        <template #title>
          <h4>Outbond</h4>
          <!-- <b-spinner type="grow" small></b-spinner> I'm <i>custom</i> <strong>title</strong> -->
        </template>

        <template>
          <!-- <b-card> -->
          <b-row class="align-items-center">
            <b-col sm="12" md="6" class="my-1">
              <!-- <b-button variant="primary" @click.prevent="$router.push('/disposal')">
                <feather-icon icon="ShoppingBagIcon" class="mr-50" />
                Disposal
              </b-button> -->
            </b-col>
            <b-col sm="12" md="6" class="mb-1 d-flex justify-content-end">
              <!-- <b-button
              variant="primary"
              @click.prevent="$router.push('/disposal')"
            >
              <feather-icon icon="ShoppingBagIcon" class="mr-50" />
              Disposal
            </b-button> -->
              <b-button-group>
                <b-button @click="perorder" variant="outline-primary">
                  <feather-icon icon="MenuIcon" class="mr-25" />Per Order</b-button>
                <b-button @click="peritems" variant="outline-warning">
                  <feather-icon icon="PackageIcon" class="mr-25" />Per Items</b-button>
              </b-button-group>
            </b-col>
          </b-row>
          <!-- </b-card> -->
        </template>
        <template v-if="mode == 'Per Items'">
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <list-items />
            </b-col>
          </b-row>
        </template>
        <template v-if="mode == 'Per Order'">
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <list-order />
            </b-col>
          </b-row>
        </template>
      </b-tab>
      <b-tab v-if="this.user.level != 'SPV CRM'">
        <template #title>
          <h4>Disposal</h4>
          <!-- <b-spinner type="grow" small></b-spinner> I'm <i>custom</i> <strong>title</strong> -->
        </template>
        <page-disposal />
      </b-tab>
    </b-tabs>
    <!-- <template >
      <template>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <dashboard-penerimaan />
            </b-col>
        </b-row>
      </template>
    </template> -->
  </main>
</template>
<script>
// import { useSound } from '@vueuse/sound'
import { BAlert, BAvatar, BRow, BCol, BCard, BFormInput, BButton, BButtonGroup, BTabs, BTab, } from "bootstrap-vue";
// import SedangPerbaikan from "./../../../SedangPerbaikan.vue"
import ListItems from "./ListByOrderItems.vue";
import ListOrder from "./ListByOrder.vue";
import PageDisposal from "./disposal/List.vue";
export default {
  // setup() {
  //   const { play } = useSound(buttonSfx)
  //   return {
  //     play,
  //   }
  // },
  data() {
    return {
      mode: 'Per Items',
      level: JSON.parse(localStorage.getItem("userData")).level,
      rekapKomisi: null,
      rekapPoin: null,
      rekapKomisiCabang: null,
    };
  },
  components: {
    BTabs, BTab,
    ListItems,
    ListOrder,
    PageDisposal,
    // SedangPerbaikan,
    BAlert,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton, BButtonGroup,
  },
  computed: {
    myName() {
      if (this.user) {
        return this.user.karyawan ? this.user.karyawan.nama_lengkap : this.user.email;
      }

      return null;
    },

    cekAffiliate() {
      let level = this.user.level.nama_level;
      if (level.toUpperCase() == "CABANG" || level.toUpperCase() == "MARKETING") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    perorder() {
      this.mode = 'Per Order'
    },
    peritems() {
      this.mode = 'Per Items'
    },
    copyUrl(link) {
      navigator.clipboard.writeText(`${link}`);
      this.displaySuccess({
        message: "Berhasil disalin",
      });
    },
    getRekapData() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "affiliate",
      };
      this.$store
        .dispatch("komisi/rekapKomisi", payload)
        .then((res) => {
          this.rekapKomisi = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
    getRekapDataCabang() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "cabang",
      };
      this.$store
        .dispatch("komisi/rekapKomisi", payload)
        .then((res) => {
          this.rekapKomisiCabang = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
    getRekapDataPoin() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "affiliate",
      };
      this.$store
        .dispatch("poin/getRekap", payload)
        .then((res) => {
          this.rekapPoin = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
  },
  created() {
    if (this.isOwner || this.isCEO || this.isFinance) {

      return false;
    } else {
      // this.getRekapData();
      // this.getRekapDataCabang();
      // this.getRekapDataPoin();
    }
  },
};
</script>
