var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Per Order"
    }
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Date")])]), _c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        mode: 'range'
      }
    },
    model: {
      value: _vm.rangeDate,
      callback: function ($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "7",
      "lg": "7",
      "md": "7",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.todaydate();
      }
    }
  }, [_vm._v("Today")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.seventodaydate();
      }
    }
  }, [_vm._v("7 Days")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.tigapuluhtodaydate();
      }
    }
  }, [_vm._v("30 Days")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "6"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": 'Per page',
      "label-for": "perPageSelect"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "10",
      "lg": "10",
      "md": "8",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Search'),
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1)], 1), _vm.isFinance || _vm.isOwner || _vm.isCEO ? _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Bayar",
      "label-for": "status_bayar"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "status_bayar",
      "options": _vm.optstatus_bayar
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.status_bayar,
      callback: function ($$v) {
        _vm.status_bayar = $$v;
      },
      expression: "status_bayar"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "statuskirim"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "statuskirim",
      "options": _vm.optstatuskirim
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.statuskirim,
      callback: function ($$v) {
        _vm.statuskirim = $$v;
      },
      expression: "statuskirim"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Daftar yang sudah masuk ke akun dan kas",
      "label-for": "is_accounted"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_accounted",
      "options": _vm.optis_accounted
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_accounted,
      callback: function ($$v) {
        _vm.is_accounted = $$v;
      },
      expression: "is_accounted"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Pilih Affiliator",
      "label-for": "is_member"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_member",
      "options": _vm.optis_member
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_member,
      callback: function ($$v) {
        _vm.is_member = $$v;
      },
      expression: "is_member"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.isCabang || _vm.isMarketing ? _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Bayar",
      "label-for": "status_bayar"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "status_bayar",
      "options": _vm.optstatus_bayar
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.status_bayar,
      callback: function ($$v) {
        _vm.status_bayar = $$v;
      },
      expression: "status_bayar"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "statuskirim"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "statuskirim",
      "options": _vm.optstatuskirim
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.statuskirim,
      callback: function ($$v) {
        _vm.statuskirim = $$v;
      },
      expression: "statuskirim"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "typeTransaksi"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "typeTransaksi",
      "options": _vm.opttypetransaksi
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.typeTransaksi,
      callback: function ($$v) {
        _vm.typeTransaksi = $$v;
      },
      expression: "typeTransaksi"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('b-table', {
    ref: "selectableTable",
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "select-mode": _vm.selectMode,
      "responsive": "sm",
      "selectable": "",
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "row-selected": _vm.onRowSelected,
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(selected)",
      fn: function (_ref) {
        var rowSelected = _ref.rowSelected;
        return [rowSelected ? [_c('span', {
          attrs: {
            "aria-hidden": "true"
          }
        }, [_vm._v("✓")]), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Selected")])] : [_c('span', {
          attrs: {
            "aria-hidden": "true"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Not selected")])]];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(tanggal)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.createAt ? _vm.humanDateTime(item.createAt) : _vm.humanDateTime(item.waktu_transaksi)) + " ")];
      }
    }, {
      key: "cell(no_invoice)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.externalOrderId ? item.externalOrderId : item.no_invoice) + " ")];
      }
    }, {
      key: "cell(statuswms)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.status ? item.status : item.statuswms) + " ")];
      }
    }, {
      key: "cell(status_bayar)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.status_bayar == 'lunas' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("LUNAS")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("BELUM LUNAS")])];
      }
    }, {
      key: "cell(payment_option)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [item.payment_option == 'manual_transfer' ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Manual Transfer")]) : item.payment_option == 'pg_midtrans' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Otomatis")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("COD")])];
      }
    }, {
      key: "cell(status)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item.status == 'selesai' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("SELESAI")]) : item.status == 'diproses' ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("DIPROSES")]) : item.status == 'dikirim' ? _c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("DIKIRIM")]) : item.status == 'diterima' ? _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("DITERIMA")]) : item.status == 'dibatalkan' ? _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("DIBATALKAN")]) : _c('b-badge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("PENDING")])];
      }
    }, {
      key: "cell(rincian)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.rincian[0] ? item.rincian[0].nama_barang : "-") + " ")];
      }
    }, {
      key: "cell(waktu_transaksi)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('strong', [_vm._v(_vm._s(_vm.humanDateTime(item.waktu_transaksi)))])];
      }
    }, {
      key: "cell(uang_masuk)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.uang_masuk)))])];
      }
    }, {
      key: "cell(total_bayar)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.total_bayar)))])];
      }
    }, {
      key: "cell(transaction_type)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.transaction_type == "jasa" ? "Jasa" : "Barang"))])];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [item.transaction_type == 'jasa' && item.status != 'dibatalkan' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Pilih Terapis',
            expression: "'Pilih Terapis'",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.modalTerapis(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1) : _vm._e(), item.status == 0 && _vm.allowUpdate() && item.tak_tertagih == 0 && item.bayar == 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/edit/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(actionFinance)",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.status_bayar != 'lunas' ? _c('b-button', {
          attrs: {
            "title": "Konfirmasi Pembayaran Manual",
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasiawal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1) : _vm._e(), item.status_bayar != 'lunas' && item.payment_option == 'pg_midtrans' ? _c('b-button', {
          attrs: {
            "title": "Cek Pembayaran Otomatis",
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasipg(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "DollarSignIcon"
          }
        })], 1) : _vm._e(), _c('b-modal', {
          attrs: {
            "hide-backdrop": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "id": "modal-konfirmasi",
            "ok-variant": "secondary",
            "centered": "",
            "title": 'Konfirmasi Pembayaran ' + _vm.formkonfirmasi.no_invoice
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function () {
              return [_c('section', {
                staticClass: "d-flex justify-content-end align-items-center"
              }, [_c('b-button', {
                attrs: {
                  "size": "sm",
                  "variant": "danger"
                },
                on: {
                  "click": function ($event) {
                    _vm.showKonfirmasi = false;
                    _vm.getTransaksi();
                  }
                }
              }, [_vm._v(" Keluar ")])], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.showKonfirmasi,
            callback: function ($$v) {
              _vm.showKonfirmasi = $$v;
            },
            expression: "showKonfirmasi"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "xl": "12",
            "lg": "12",
            "md": "12",
            "sm": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "primary",
            "block": ""
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submitkonfirmasi($event);
            }
          }
        }, [_vm._v(" Konfirmasi Lunas ")]), _c('b-overlay', {
          attrs: {
            "show": _vm.loadingfoto
          }
        }, [_c('h3', {
          attrs: {
            "align": "center"
          }
        }, [_vm._v("Bukti Transfer")]), _vm.formkonfirmasi.bukti_tf ? [_c('b-img', {
          attrs: {
            "src": _vm.apiFile + _vm.formkonfirmasi.bukti_tf.file,
            "alt": "",
            "fluid-grow": ""
          }
        })] : [_c('center', [_c('h3', {
          staticClass: "text-danger"
        }, [_vm._v("Belum diupload")])])]], 2)], 1)], 1)], 1)];
      }
    }])
  }), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "6"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Search'),
      "label-for": "filterInput2"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInpu2t",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter2,
      callback: function ($$v) {
        _vm.filter2 = $$v;
      },
      expression: "filter2"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter2
    },
    on: {
      "click": function ($event) {
        _vm.filter2 = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Scan Barcode'),
      "label-for": "filterbarcode"
    }
  }, [_c('b-form-tags', {
    staticClass: "mb-2",
    attrs: {
      "id": "filterbarcode",
      "tag-variant": "success",
      "no-outer-focus": "",
      "placeholder": "Enter a new barcode value and click Add or enter",
      "state": _vm.state
    },
    on: {
      "input": function ($event) {
        return _vm.resetInputValue();
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref16) {
        var tags = _ref16.tags,
          inputId = _ref16.inputId,
          placeholder = _ref16.placeholder,
          addTag = _ref16.addTag,
          removeTag = _ref16.removeTag;
        return [_c('b-input-group', [_c('b-form-input', {
          ref: "autoFocusInput2",
          attrs: {
            "id": inputId,
            "placeholder": placeholder
          },
          on: {
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              addTag(_vm.newTag);
              _vm.cekbarcode(_vm.newTag);
            }
          },
          model: {
            value: _vm.newTag,
            callback: function ($$v) {
              _vm.newTag = $$v;
            },
            expression: "newTag"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              addTag(_vm.newTag);
              _vm.cekbarcode(_vm.newTag);
            }
          }
        }, [_vm._v("Add")])], 1)], 1), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.state
          }
        }, [_vm._v(" Duplicate tag value cannot be added again! ")]), _vm.cekbar.length > 0 ? [_vm._l(_vm.cekbar, function (tag) {
          return [_c('b-badge', {
            key: tag.text,
            staticClass: "mr-1",
            attrs: {
              "title": tag.text,
              "variant": tag.value == 'ada' ? 'success' : 'danger'
            },
            on: {
              "remove": function ($event) {
                return removeTag(tag);
              }
            }
          }, [_vm._v(_vm._s(tag.text) + " "), _c('b-button', {
            staticClass: "btn-icon",
            attrs: {
              "size": "sm",
              "variant": "light"
            },
            on: {
              "click": function ($event) {
                return _vm.removecekbar(tag);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "XIcon"
            }
          })], 1)], 1)];
        })] : _c('b-form-text', [_vm._v(" There are no tags specified. Add a new tag above. ")])];
      }
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-table', {
    attrs: {
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage2,
      "items": _vm.selecteditem,
      "fields": _vm.fieldsdetail,
      "filter": _vm.filter2,
      "filter-included-fields": _vm.filterOn2
    },
    on: {
      "filtered": _vm.onFiltered2
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref17) {
        var index = _ref17.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(barang_nama)",
      fn: function (_ref18) {
        var item = _ref18.item;
        return [_vm._v(" " + _vm._s(item.varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.kategori.kategori) + " / " + _vm._s(item.barang_nama))])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref19) {
        var item = _ref19.item;
        return [_vm._v(" " + _vm._s(item.kode)), _c('br'), _c('small', [_vm._v(_vm._s(item.no_batch) + " ")])];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref20) {
        var item = _ref20.item;
        return [_vm._v(" " + _vm._s(item.qty ? item.qty : 0) + " " + _vm._s(item.satuan ? item.satuan.satuan : '-') + " ")];
      }
    }, {
      key: "cell(status_bayar)",
      fn: function (_ref21) {
        var item = _ref21.item;
        return [item.status_bayar == 'lunas' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("LUNAS")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("BELUM LUNAS")])];
      }
    }, {
      key: "cell(payment_option)",
      fn: function (_ref22) {
        var item = _ref22.item;
        return [item.payment_option == 'manual_transfer' ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Manual Transfer")]) : item.payment_option == 'pg_midtrans' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Otomatis")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("COD")])];
      }
    }, {
      key: "cell(status)",
      fn: function (_ref23) {
        var item = _ref23.item;
        return [item.status == 'selesai' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("SELESAI")]) : item.status == 'diproses' ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("DIPROSES")]) : item.status == 'dikirim' ? _c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("DIKIRIM")]) : item.status == 'diterima' ? _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("DITERIMA")]) : item.status == 'dibatalkan' ? _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("DIBATALKAN")]) : _c('b-badge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("PENDING")])];
      }
    }, {
      key: "cell(rincian)",
      fn: function (_ref24) {
        var item = _ref24.item;
        return [_vm._v(" " + _vm._s(item.rincian[0] ? item.rincian[0].nama_barang : "-") + " ")];
      }
    }, {
      key: "cell(waktu_transaksi)",
      fn: function (_ref25) {
        var item = _ref25.item;
        return [_c('strong', [_vm._v(_vm._s(_vm.humanDateTime(item.waktu_transaksi)))])];
      }
    }, {
      key: "cell(uang_masuk)",
      fn: function (_ref26) {
        var item = _ref26.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.uang_masuk)))])];
      }
    }, {
      key: "cell(total_bayar)",
      fn: function (_ref27) {
        var item = _ref27.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.total_bayar)))])];
      }
    }, {
      key: "cell(transaction_type)",
      fn: function (_ref28) {
        var item = _ref28.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.transaction_type == "jasa" ? "Jasa" : "Barang"))])];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref29) {
        var item = _ref29.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.transaction_type == 'jasa' && item.status != 'dibatalkan' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Pilih Terapis',
            expression: "'Pilih Terapis'",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.modalTerapis(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1) : _vm._e(), item.status == 0 && _vm.allowUpdate() && item.tak_tertagih == 0 && item.bayar == 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/edit/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(actionFinance)",
      fn: function (_ref30) {
        var item = _ref30.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.status_bayar != 'lunas' ? _c('b-button', {
          attrs: {
            "title": "Konfirmasi Pembayaran Manual",
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasiawal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1) : _vm._e(), item.status_bayar != 'lunas' && item.payment_option == 'pg_midtrans' ? _c('b-button', {
          attrs: {
            "title": "Cek Pembayaran Otomatis",
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasipg(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "DollarSignIcon"
          }
        })], 1) : _vm._e(), _c('b-modal', {
          attrs: {
            "hide-backdrop": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "id": "modal-konfirmasi",
            "ok-variant": "secondary",
            "centered": "",
            "title": 'Konfirmasi Pembayaran ' + _vm.formkonfirmasi.no_invoice
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function () {
              return [_c('section', {
                staticClass: "d-flex justify-content-end align-items-center"
              }, [_c('b-button', {
                attrs: {
                  "size": "sm",
                  "variant": "danger"
                },
                on: {
                  "click": function ($event) {
                    _vm.showKonfirmasi = false;
                    _vm.getTransaksi();
                  }
                }
              }, [_vm._v(" Keluar ")])], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.showKonfirmasi,
            callback: function ($$v) {
              _vm.showKonfirmasi = $$v;
            },
            expression: "showKonfirmasi"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "xl": "12",
            "lg": "12",
            "md": "12",
            "sm": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "primary",
            "block": ""
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submitkonfirmasi($event);
            }
          }
        }, [_vm._v(" Konfirmasi Lunas ")]), _c('b-overlay', {
          attrs: {
            "show": _vm.loadingfoto
          }
        }, [_c('h3', {
          attrs: {
            "align": "center"
          }
        }, [_vm._v("Bukti Transfer")]), _vm.formkonfirmasi.bukti_tf ? [_c('b-img', {
          attrs: {
            "src": _vm.apiFile + _vm.formkonfirmasi.bukti_tf.file,
            "alt": "",
            "fluid-grow": ""
          }
        })] : [_c('center', [_c('h3', {
          staticClass: "text-danger"
        }, [_vm._v("Belum diupload")])])]], 2)], 1)], 1)], 1)];
      }
    }])
  }), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }