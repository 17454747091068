var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Per Items"
    }
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Date")])]), _c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        mode: 'range'
      }
    },
    model: {
      value: _vm.rangeDate,
      callback: function ($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "7",
      "lg": "7",
      "md": "7",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.todaydate();
      }
    }
  }, [_vm._v("Today")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.seventodaydate();
      }
    }
  }, [_vm._v("7 Days")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.tigapuluhtodaydate();
      }
    }
  }, [_vm._v("30 Days")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": 'Per page',
      "label-for": "perPageSelect"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "10",
      "lg": "10",
      "md": "8",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Search'),
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1)], 1), _vm.isFinance || _vm.isOwner || _vm.isCEO ? _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Bayar",
      "label-for": "status_bayar"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "status_bayar",
      "options": _vm.optstatus_bayar
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.status_bayar,
      callback: function ($$v) {
        _vm.status_bayar = $$v;
      },
      expression: "status_bayar"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "statuskirim"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "statuskirim",
      "options": _vm.optstatuskirim
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.statuskirim,
      callback: function ($$v) {
        _vm.statuskirim = $$v;
      },
      expression: "statuskirim"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Daftar yang sudah masuk ke akun dan kas",
      "label-for": "is_accounted"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_accounted",
      "options": _vm.optis_accounted
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_accounted,
      callback: function ($$v) {
        _vm.is_accounted = $$v;
      },
      expression: "is_accounted"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Pilih Affiliator",
      "label-for": "is_member"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_member",
      "options": _vm.optis_member
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_member,
      callback: function ($$v) {
        _vm.is_member = $$v;
      },
      expression: "is_member"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.isCabang || _vm.isMarketing ? _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Bayar",
      "label-for": "status_bayar"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "status_bayar",
      "options": _vm.optstatus_bayar
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.status_bayar,
      callback: function ($$v) {
        _vm.status_bayar = $$v;
      },
      expression: "status_bayar"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "statuskirim"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "statuskirim",
      "options": _vm.optstatuskirim
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.statuskirim,
      callback: function ($$v) {
        _vm.statuskirim = $$v;
      },
      expression: "statuskirim"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "typeTransaksi"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "typeTransaksi",
      "options": _vm.opttypetransaksi
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.typeTransaksi,
      callback: function ($$v) {
        _vm.typeTransaksi = $$v;
      },
      expression: "typeTransaksi"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('b-table', {
    attrs: {
      "tbody-tr-class": _vm.rowClass,
      "small": "",
      "hover": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "responsive": "sm",
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(selected)",
      fn: function (_ref) {
        var rowSelected = _ref.rowSelected;
        return [rowSelected ? [_c('span', {
          attrs: {
            "aria-hidden": "true"
          }
        }, [_vm._v("✓")]), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Selected")])] : [_c('span', {
          attrs: {
            "aria-hidden": "true"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Not selected")])]];
      }
    }, {
      key: "cell(barcode)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.item_data.barcode ? item.item_data.barcode.split('/').pop().split('.png')[0] : item.item_data.kode) + " ")];
      }
    }, {
      key: "cell(item_data)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.item_data.varian ? item.item_data.varian : item.item_data.variationName)), _c('br'), _vm._v(" " + _vm._s(item.item_data.sku ? item.item_data.sku : item.item_data.no_batch) + " ")];
      }
    }, {
      key: "cell(quantity)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.item_data.qty ? item.item_data.qty : item.item_data.quantity) + " ")];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref5) {
        var index = _ref5.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(tanggal)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.item_data.createAt ? _vm.humanDateTime(item.item_data.createAt) : _vm.humanDateTime(item.item_data.waktu_transaksi)) + " ")];
      }
    }, {
      key: "cell(no_invoice)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item ? item.item_data.externalOrderId : item.item_data.no_invoice) + " ")];
      }
    }, {
      key: "cell(qtyScanned)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.item_data.qtyScanned) + " ")];
      }
    }, {
      key: "cell(scanned_qty)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.item_data.scanned_qty) + " ")];
      }
    }, {
      key: "cell(scanned)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.item_data.scanned) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [item.item_data.transaction_type == 'jasa' && item.item_data.status != 'dibatalkan' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Pilih Terapis',
            expression: "'Pilih Terapis'",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.modalTerapis(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1) : _vm._e(), item.item_data.status == 0 && _vm.allowUpdate() && item.item_data.tak_tertagih == 0 && item.item_data.bayar == 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/edit/" + (item.item_data.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(actionFinance)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/detail/" + (item.item_data.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.item_data.status_bayar != 'lunas' ? _c('b-button', {
          attrs: {
            "title": "Konfirmasi Pembayaran Manual",
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasiawal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1) : _vm._e(), item.item_data.status_bayar != 'lunas' && item.item_data.payment_option == 'pg_midtrans' ? _c('b-button', {
          attrs: {
            "title": "Cek Pembayaran Otomatis",
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasipg(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "DollarSignIcon"
          }
        })], 1) : _vm._e(), _c('b-modal', {
          attrs: {
            "hide-backdrop": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "id": "modal-konfirmasi",
            "ok-variant": "secondary",
            "centered": "",
            "title": 'Konfirmasi Pembayaran ' + _vm.formkonfirmasi.no_invoice
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function () {
              return [_c('section', {
                staticClass: "d-flex justify-content-end align-items-center"
              }, [_c('b-button', {
                attrs: {
                  "size": "sm",
                  "variant": "danger"
                },
                on: {
                  "click": function ($event) {
                    _vm.showKonfirmasi = false;
                    _vm.getTransaksi();
                  }
                }
              }, [_vm._v(" Keluar ")])], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.showKonfirmasi,
            callback: function ($$v) {
              _vm.showKonfirmasi = $$v;
            },
            expression: "showKonfirmasi"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "xl": "12",
            "lg": "12",
            "md": "12",
            "sm": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "primary",
            "block": ""
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submitkonfirmasi($event);
            }
          }
        }, [_vm._v(" Konfirmasi Lunas ")]), _c('b-overlay', {
          attrs: {
            "show": _vm.loadingfoto
          }
        }, [_c('h3', {
          attrs: {
            "align": "center"
          }
        }, [_vm._v("Bukti Transfer")]), _vm.formkonfirmasi.bukti_tf ? [_c('b-img', {
          attrs: {
            "src": _vm.apiFile + _vm.formkonfirmasi.bukti_tf.file,
            "alt": "",
            "fluid-grow": ""
          }
        })] : [_c('center', [_c('h3', {
          staticClass: "text-danger"
        }, [_vm._v("Belum diupload")])])]], 2)], 1)], 1)], 1)];
      }
    }])
  }), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "5"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Scan Barcode'),
      "label-for": "filterbarcode"
    }
  }, [_c('b-form-tags', {
    staticClass: "mb-2",
    attrs: {
      "id": "filterbarcode",
      "tag-variant": "success",
      "no-outer-focus": "",
      "placeholder": "Enter a new barcode value and click Add or enter",
      "state": _vm.state
    },
    on: {
      "input": function ($event) {
        return _vm.resetInputValue();
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref13) {
        var tags = _ref13.tags,
          inputId = _ref13.inputId,
          placeholder = _ref13.placeholder,
          addTag = _ref13.addTag,
          removeTag = _ref13.removeTag;
        return [_c('b-input-group', [_c('b-form-input', {
          ref: "autoFocusInput",
          attrs: {
            "id": inputId,
            "placeholder": placeholder,
            "autoFocusInput2": ""
          },
          on: {
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              addTag(_vm.newTag);
              _vm.cekbarcode(_vm.newTag);
            }
          },
          model: {
            value: _vm.newTag,
            callback: function ($$v) {
              _vm.newTag = $$v;
            },
            expression: "newTag"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              addTag(_vm.newTag);
              _vm.cekbarcode(_vm.newTag);
            }
          }
        }, [_vm._v("Add")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.saveScan();
            }
          }
        }, [_vm._v("Save Scan")])], 1)], 1), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.state
          }
        }, [_vm._v(" Duplicate tag value cannot be added again! ")]), _vm.cekbar.length > 0 ? [_vm._l(_vm.cekbar, function (tag) {
          return [_c('b-badge', {
            key: tag.text,
            staticClass: "mr-1",
            attrs: {
              "title": tag.text,
              "variant": tag.value == 'ada' ? 'success' : 'danger'
            },
            on: {
              "remove": function ($event) {
                return removeTag(tag);
              }
            }
          }, [_vm._v(_vm._s(tag.text) + " "), _c('b-button-group', [_c('b-button', {
            staticClass: "btn-icon",
            attrs: {
              "size": "sm",
              "variant": "light"
            },
            on: {
              "click": function ($event) {
                return _vm.updateQtymin(tag);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "MinusIcon"
            }
          })], 1), _c('b-button', {
            staticClass: "btn-icon",
            attrs: {
              "size": "sm",
              "variant": "light"
            }
          }, [_vm._v(" " + _vm._s(tag.qty) + " ")]), _c('b-button', {
            staticClass: "btn-icon mr-1",
            attrs: {
              "size": "sm",
              "variant": "light"
            },
            on: {
              "click": function ($event) {
                return _vm.cekbarcode(tag.text);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "PlusIcon"
            }
          })], 1)], 1), _c('b-button', {
            staticClass: "btn-icon",
            attrs: {
              "size": "sm",
              "variant": "light"
            },
            on: {
              "click": function ($event) {
                return _vm.removecekbar(tag);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "XIcon"
            }
          })], 1)], 1)];
        })] : _c('b-form-text', [_vm._v(" There are no tags specified. Add a new tag above. ")])];
      }
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }